import { roundFigure } from '/~/utils/format'

export function formatPoints(
  value: number | bigint | Intl.StringNumericLiteral
) {
  const formatter = new Intl.NumberFormat('en-AU', {
    maximumFractionDigits: 0,
  })

  return formatter.format(value || 0)
}

export function calculatePurchasePointsEarned(
  value: number,
  rebatePercentage: number,
  pointsEarnRate: number
) {
  const rebateValue = (value / 100) * rebatePercentage

  return pointsEarnRate !== 0 ? roundFigure(rebateValue / pointsEarnRate) : 0
}

export function calculatePaymentPointsEarned(
  value: number,
  pointsEarnRate: number
) {
  return roundFigure(value * pointsEarnRate)
}
