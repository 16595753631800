import {
  configurePaymentElements,
  createPaymentCard,
} from '@eonx-com/payment-elements'
import { computed, ref } from 'vue'
import {
  type ApiResponseData,
  PaymentMethod,
  PaymentMethodBankAccount,
  PaymentMethodConfig,
  PaymentMethodCreditCard,
  PaymentMethodStatus,
} from '/~/types/api'
import api from '/~/core/api'
import emitter from '/~/core/emitter'
import { roundFigure } from '/~/utils/format/numeric'
import { FlowType, getOrderType } from '/~/composables/checkout/checkout-types'
import { useCms } from '/~/composables/cms'
import { useExtensions } from '/~/composables/extensions'
import { filterByType, PaymentMethodType } from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'
import { useWithdraw } from '/~/composables/withdraw/use-withdraw'

type OrderType = keyof PaymentMethodConfig['config']

const { getManifestByName } = useExtensions()

const paymentMethods = ref<PaymentMethod[]>([])
const config = ref<PaymentMethodConfig[]>([])
const initiatingAccountsIds = ref<string[]>([])

const fetchingList = ref(false)
const fetchingDetails = ref(false)

const loaded = ref(false)
const creating = ref(false)
const verifying = ref(false)
const deleting = ref(false)
const configLoading = ref(false)

const getMethodIndex = (id: string) =>
  paymentMethods.value.findIndex((pm) => pm.id === id)

const isVisaEnabled = computed(() => true)
const isMasterCardEnabled = computed(() => true)
const isAmexEnabled = computed(() => false)

const points = computed(() =>
  filterByType(paymentMethods.value, PaymentMethodType.points)
)
const eWallets = computed(() =>
  filterByType(paymentMethods.value, PaymentMethodType.eWallet)
)
const creditCards = computed(() =>
  filterByType(paymentMethods.value, PaymentMethodType.creditCard)
)
const bankAccounts = computed(() =>
  filterByType(paymentMethods.value, PaymentMethodType.bankAccount)
)

const defaultEwallet = computed(() => eWallets.value[0] ?? null)

const listReady = computed(() => loaded.value && !fetchingList.value) // TODO: remove loaded?

const noCreditCards = computed(
  () => listReady.value && !creditCards.value.length
)
const noBankAccounts = computed(
  () => listReady.value && !bankAccounts.value.length
)
const noEwallets = computed(() => listReady.value && !eWallets.value.length)
const noPoints = computed(() => listReady.value && !points.value.length)

const isPurchaseOrderPointsAvailable = computed(() =>
  isMethodAvailable(PaymentMethodType.points, FlowType.purchase)
)
const isPurchaseOrderEwalletsAvailable = computed(() =>
  isMethodAvailable(PaymentMethodType.eWallet, FlowType.purchase)
)
const isPurchaseOrderCreditCardsAvailable = computed(() =>
  isMethodAvailable(PaymentMethodType.creditCard, FlowType.purchase)
)
const isPurchaseOrderBankAccountsAvailable = computed(() =>
  isMethodAvailable(PaymentMethodType.bankAccount, FlowType.purchase)
)
const isPbaEnabled = computed(() =>
  Boolean(getManifestByName('pay-by-account'))
)

const hasLockedBankAccount = computed(() =>
  bankAccounts.value.some((a) => a.status === 'locked')
)

const everyCreditCardPayable = computed(() => {
  const { isCreditCardsNavEnabled } = useProvider()

  if (!isCreditCardsNavEnabled.value) {
    return true
  }

  return creditCards.value.every(
    (pm) => !['new', 'pending', 'locked'].includes(pm.status.toLowerCase())
  )
})
const everyBankAccountPayable = computed(() => {
  const { isBankAccountsNavEnabled } = useProvider()

  if (!isBankAccountsNavEnabled.value) {
    return true
  }

  return bankAccounts.value.every(
    (pm) => !['new', 'pending', 'locked'].includes(pm.status.toLowerCase())
  )
})

function hasUnavailableMethods(orderType: OrderType) {
  const isCreditCardsAvailable = isMethodAvailable(
    PaymentMethodType.creditCard,
    orderType
  )
  const isBankAccountsAvailable = isMethodAvailable(
    PaymentMethodType.bankAccount,
    orderType
  )

  const isBothUnavailable = !isCreditCardsAvailable && !isBankAccountsAvailable
  const isBothNotPayable =
    !everyCreditCardPayable.value && !everyBankAccountPayable.value

  return (
    isBothUnavailable ||
    isBothNotPayable ||
    (!everyCreditCardPayable.value && !isBankAccountsAvailable) ||
    (!everyBankAccountPayable.value && !isCreditCardsAvailable)
  )
}

interface Tokenisation {
  apiKey: string
  expiresAt: string
}

const createAPIToken = async () => {
  try {
    const response = await api.post<ApiResponseData<Tokenisation>>(
      '/v3/payment-methods/api-keys/tokenisation'
    )

    return response.data.apiKey
  } catch (error: unknown) {
    console.error('payment-methods', error)
    return undefined
  }
}

const formLoading = ref(false)
const formReady = ref(false)
const creditCardFormData = ref({})

function initPaymentMethodsForm(config = {}) {
  const { isDarkThemeForEwallet, primaryColor } = useCms()
  const { providerLocale, providerCurrency, cardProtectionIssuingWhitelist } =
    useProvider()

  creditCardFormData.value = {}

  configurePaymentElements({
    test: eonx.env !== 'production',
    locale: providerLocale.value,
    currency: providerCurrency.value,
    cardTypes: cardProtectionIssuingWhitelist.value,
    themes: {
      '*': {
        fonts: [
          {
            css: 'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Roboto&display=swap',
          },
        ],
        styles: {
          colorPrimary: primaryColor.value,
          colorInputLabel: isDarkThemeForEwallet.value ? '#FFFFFF' : '#1F2937',
          fontFamily: 'Lato',
          colorDanger: '#DC2626',
          colorText: '#37474f',
        },
      },
    },
    ...config,
  })
}

async function createCreditCardForm(options: {
  containerId: string
  onMounted?: () => void
  onFailed?: () => void
}) {
  formReady.value = false
  formLoading.value = true
  const { user } = useUser()

  initPaymentMethodsForm()

  const apiKey = await createAPIToken()

  return createPaymentCard({
    theme: 'light',
    apiKey: apiKey || '',
    container: options.containerId,
    cardholder: user.value.fullName || '',
    onMounted() {
      formLoading.value = false
      formReady.value = true
      options.onMounted?.()
    },
    onProcess() {
      creating.value = true
    },
    onChange(card) {
      creditCardFormData.value = card
    },
    onError(error) {
      console.error(error)
      formLoading.value = false
      creating.value = false
    },
    async onSuccess({ id }: { id: string }) {
      try {
        const { data } = await api.post<
          ApiResponseData<PaymentMethodCreditCard>
        >('/v3/payment-methods/credit-cards', {
          token: id,
          default: false,
        })

        const newCard = {
          ...data,
          status: data?.status?.toLowerCase() as PaymentMethodStatus,
        }

        paymentMethods.value.unshift(newCard)

        emitter.emit('payment-methods:created', newCard)
        emitter.emit('payment-methods:updated')
      } catch (e) {
        options.onFailed?.()
        console.error(e)
      } finally {
        creating.value = false
        formLoading.value = false
        formReady.value = false
        creditCardFormData.value = {}
      }
    },
  })
}

interface BankAccountPayload {
  bsb: string
  accountNumber: string
  accountName: string
  termsAndConditions: boolean
}

const createBankAccount = async (bankAccountDetails: BankAccountPayload) => {
  creating.value = true

  try {
    const { data } = await api.post<ApiResponseData<PaymentMethodBankAccount>>(
      '/v3/payment-methods/bank-accounts',
      bankAccountDetails
    )

    const newBankAccount = {
      ...data,
      status: data?.status?.toLowerCase() as PaymentMethodStatus,
    }

    paymentMethods.value.unshift(newBankAccount)

    emitter.emit('payment-methods:created', newBankAccount)
    emitter.emit('payment-methods:updated')

    return data
  } catch (error) {
    console.error('payment-methods', error)
    throw error
  } finally {
    creating.value = false
  }
}

function buildPaymentQuery(currentFlowType?: FlowType, payeeId?: string) {
  const query: Record<string, string> = {}

  if (!currentFlowType || !Object.values(FlowType).includes(currentFlowType)) {
    return query
  }

  const orderType = getOrderType(currentFlowType)

  if (orderType) {
    query.orderType = orderType
  }
  if (payeeId) {
    query.payeeId = payeeId
  }
  return query
}

const getPaymentMethods = (currentFlowType?: FlowType, payeeId?: string) => {
  return api
    .get<ApiResponseData<PaymentMethod[]>>('/v3/payment-methods', {
      query: buildPaymentQuery(currentFlowType, payeeId),
    })
    .then(({ data: paymentMethods = [] }) => {
      return paymentMethods
        .filter((i) => Boolean(i))
        .map((i) => {
          const result = {
            ...i,
            status:
              'status' in i ? i.status && i.status.toLowerCase() : undefined, // TODO: remove
          }

          // use balance from /v3/ewallets and /v3/points/balance
          if ('availableBalance' in result) {
            delete result.availableBalance
          }
          if ('unclearedBalance' in result) {
            delete result.unclearedBalance
          }
          if ('balance' in result) {
            delete result.balance
          }

          return result
        }) as PaymentMethod[]
    })
    .then((paymentMethods) => {
      emitter.emit('payment-methods:fetched')

      return paymentMethods
    })
}

async function fetchPaymentMethods(currentFlowType: FlowType, payeeId: string) {
  if (fetchingList.value) {
    return
  }

  fetchingList.value = true

  try {
    paymentMethods.value = await getPaymentMethods(currentFlowType, payeeId)

    loaded.value = true
  } catch (error) {
    console.error('payment-methods', error)
  } finally {
    fetchingList.value = false
  }
}

const getPaymentMethod = async (id: string) => {
  fetchingDetails.value = true

  const index = getMethodIndex(id)

  if (index !== -1) {
    fetchingDetails.value = false

    return paymentMethods.value[index]
  }

  try {
    const { data } = await api.get<ApiResponseData<PaymentMethod>>(
      `/v3/payment-methods/${id}`
    )

    fetchingDetails.value = false

    return data
  } catch (e) {
    fetchingDetails.value = false

    throw e
  }
}

const deletePaymentMethod = async (id: string) => {
  deleting.value = true

  try {
    await api.delete(`/v3/payment-methods/${id}`)

    const { withdrawalBankAccounts } = useWithdraw()
    const methodIndex = getMethodIndex(id)
    const withdrawalBankAccountIndex = withdrawalBankAccounts.value.findIndex(
      (method) => method.id === id
    )

    if (methodIndex !== -1) {
      const [deletedMethod] = paymentMethods.value.splice(methodIndex, 1)

      emitter.emit('payment-methods:removed', deletedMethod)
    }
    if (withdrawalBankAccountIndex !== -1) {
      withdrawalBankAccounts.value.splice(withdrawalBankAccountIndex, 1)
    }
  } catch (e) {
    console.error(e)
    throw e
  } finally {
    emitter.emit('payment-methods:updated')
    deleting.value = false
  }
}

const methodToVerify = ref<{
  id: string
  type: PaymentMethodType
  status: string
} | null>(null)

const setMethodToVerify = async (
  method:
    | PaymentMethodBankAccount
    | PaymentMethodCreditCard
    | Record<string, never> = {}
) => {
  const { isBankAccountsNavEnabled, isCreditCardsNavEnabled } = useProvider()

  if (
    (method.type === PaymentMethodType.creditCard &&
      !isCreditCardsNavEnabled.value) ||
    (method.type === PaymentMethodType.bankAccount &&
      !isBankAccountsNavEnabled.value)
  ) {
    emitter.emit('payment-methods:verified', method)
    return
  }

  await initPaymentMethod(method)
  methodToVerify.value = {
    id: method.id,
    type: method.type,
    status: 'status' in method ? method.status : '',
  }
}

const initPaymentMethod = async (
  method: PaymentMethodBankAccount | PaymentMethodCreditCard
) => {
  const { id, status } = method

  if (!id) return
  if (
    status?.toLowerCase() === 'new' &&
    !initiatingAccountsIds.value.includes(id)
  ) {
    initiatingAccountsIds.value.push(id)

    try {
      await api.post(
        `/v3/payment-methods/${id}/nominal-amount-verification/initiate`
      )

      const data = await getPaymentMethods()

      emitter.emit('payment-methods:updated')
      paymentMethods.value = data
    } catch (e) {
      emitter.emit('payment-methods:failed')
    } finally {
      initiatingAccountsIds.value?.splice(
        initiatingAccountsIds.value.indexOf(id),
        1
      )
    }
  }
}

const verifyNominalAmount = async (value: string) => {
  const { id } = methodToVerify.value || {}

  if (!id) {
    console.warn('no method to verify')
    return
  }

  verifying.value = true

  const guess = parseFloat(value).toFixed(2)

  try {
    const { data } = await api.post<
      ApiResponseData<PaymentMethodCreditCard | PaymentMethodBankAccount>
    >(`/v3/payment-methods/${id}/nominal-amount-verification/verify`, { guess })

    const methodIndex = getMethodIndex(id)
    const method = {
      ...data,
      status: data.status && (data.status.toLowerCase() as PaymentMethodStatus), // TODO: remove
    }

    if (methodIndex !== -1) {
      paymentMethods.value?.splice(methodIndex, 1, method)
    }

    methodToVerify.value = null

    emitter.emit('payment-methods:verified', method)
    emitter.emit('payment-methods:updated')
  } catch (e: any) {
    const error = e?.data?.message
    const isFailed = /Verification Failed/.test(error)
    const isExpired = /EonX Payment Verification expired/.test(error)

    if (isFailed || isExpired) {
      await deletePaymentMethod(id)
      methodToVerify.value = null
    }

    throw e
  } finally {
    verifying.value = false
  }
}

const getPaymentMethodsConfig = async () => {
  try {
    configLoading.value = true

    const { data } = await api.get<ApiResponseData<PaymentMethodConfig[]>>(
      '/v3/payment-methods/config'
    )

    config.value = data
  } catch (error) {
    console.error(error)
  } finally {
    configLoading.value = false
  }
}

const isMethodAvailable = (type: PaymentMethodType, place?: OrderType) => {
  const { isPointsEnabled } = usePoints()

  if (type === PaymentMethodType.points && !isPointsEnabled.value) {
    return false
  }

  const typeConfig = config.value.find((item) => item.type === type)?.config

  if (place) {
    return typeConfig?.[place]?.enabled ?? false
  } else {
    if (type === PaymentMethodType.eWallet) {
      const configPlaces: typeof typeConfig | Record<string, never> =
        typeConfig ?? {}
      const placesKeys = Object.keys(configPlaces) as Array<Partial<OrderType>>

      return placesKeys.some((i) => configPlaces?.[i]?.enabled ?? false)
    } else {
      return Boolean(typeConfig)
    }
  }
}

const canAddPaymentMethods = computed(
  () =>
    isMethodAvailable(PaymentMethodType.creditCard) ||
    isMethodAvailable(PaymentMethodType.bankAccount)
)

async function calculateFees(amount: number, paymentMethod: PaymentMethod) {
  if (!paymentMethod || amount === 0) {
    return 0
  }

  const roundedAmount = `${roundFigure(amount)}`

  try {
    const payload = {
      paymentSources: [
        {
          paymentMethodId: paymentMethod.id,
          amount: roundedAmount,
        },
      ],
    }

    const { data } = await api.post<ApiResponseData<unknown>>(
      '/v3/payment-methods/fees',
      payload
    )

    return data
  } catch (error: any) {
    throw new Error((error.data && error.data.message) || error)
  }
}

type EWalletPayloadDestination = {
  paymentMethodId: string
  amount: string
}

type EWalletPayloadSource = EWalletPayloadDestination & {
  securityToken?: string
  verificationCode?: string
}

type EWalletTopupPayload = {
  paymentDestinations: EWalletPayloadDestination[]
  paymentSources: EWalletPayloadSource[]
}

type EWalletTopupCheckout = {
  number: string
  status: string
  completedAt: string
  subtotal: string
  fee: string
  total: string
}

async function ewalletTopUp({
  securityToken,
  ewalletId,
  paymentMethod,
  cvv,
  amount,
}: {
  ewalletId: string
  paymentMethod: PaymentMethod
  amount: number
  securityToken: string
  cvv: string
}) {
  const roundedAmount = `${roundFigure(amount)}`
  const payload: EWalletTopupPayload = {
    paymentDestinations: [
      {
        paymentMethodId: ewalletId,
        amount: roundedAmount,
      },
    ],
    paymentSources: [],
    // "reference": "test topup"
  }

  if (paymentMethod.type === PaymentMethodType.creditCard) {
    payload.paymentSources.push({
      paymentMethodId: paymentMethod.id,
      amount: roundedAmount,
      securityToken,
      verificationCode: cvv,
    })
  } else if (paymentMethod.type === PaymentMethodType.bankAccount) {
    payload.paymentSources.push({
      paymentMethodId: paymentMethod.id,
      amount: roundedAmount,
    })
  }

  try {
    const { data } = await api.post<ApiResponseData<EWalletTopupCheckout>>(
      '/v3/ewallets/topup/checkout',
      payload
    )

    return data
  } catch (error: any) {
    console.error((error.data && error.data.message) || error)
    throw error
  } finally {
    emitter.emit('savings-updated')
  }
}

export const usePaymentMethods = () => ({
  config,
  paymentMethods,
  loaded,
  listReady,
  creating,
  verifying,
  deleting,
  configLoading,
  fetchingList,
  fetchingDetails,

  points,
  eWallets,
  creditCards,
  bankAccounts,
  noCreditCards,
  noBankAccounts,
  noEwallets,
  noPoints,

  defaultEwallet,

  everyBankAccountPayable,
  everyCreditCardPayable,
  hasUnavailableMethods,
  hasLockedBankAccount,

  isPurchaseOrderPointsAvailable,
  isPurchaseOrderEwalletsAvailable,
  isPurchaseOrderCreditCardsAvailable,
  isPurchaseOrderBankAccountsAvailable,
  isPbaEnabled,

  methodToVerify,
  initiatingAccountsIds,

  createCreditCardForm,
  formLoading,
  formReady,
  creditCardFormData,
  createBankAccount,
  fetchPaymentMethods,
  getPaymentMethods,
  getPaymentMethod,
  deletePaymentMethod,
  getPaymentMethodsConfig,
  setMethodToVerify,
  verifyNominalAmount,
  isMethodAvailable,

  calculateFees,
  ewalletTopUp,
  canAddPaymentMethods,

  isVisaEnabled,
  isMasterCardEnabled,
  isAmexEnabled,
})
