import { computed, ref } from 'vue'
import { FlowType, getOrderType } from '/~/composables/checkout/checkout-types'
import type { PaymentMethodMappedType } from '/~/composables/payment-methods'
import { useUser } from '/~/composables/user'

const currentTemplate = ref<
  | 'australiapost'
  | 'bill-payments'
  | 'bill-payments-v1_5'
  | 'bupa'
  | 'cashrewards'
  | 'default'
>('default')
const currentTemplateConfig = ref<Record<string, any>>({})

const providerName = computed(() => eonx.provider.subdomain) // e.g. https://subdomain.ewallet.com.au
const providerClientName = computed(() => eonx.provider.client_name ?? '')
const providerTitle = computed(() => eonx.provider.name ?? '')
const providerLogo = computed(() => eonx.provider.logo ?? '')
const providerSquareLogo = computed(() => eonx.provider.square_logo ?? '')

const providerCountry = computed(() => eonx.provider.country ?? 'AU')
const isAuProvider = computed(() => providerCountry.value === 'AU')
const isUsProvider = computed(() => providerCountry.value === 'US')
const isGbProvider = computed(() => providerCountry.value === 'GB')
const isCaProvider = computed(() => providerCountry.value === 'CA')

const providerLocale = computed(() => eonx.provider.locale ?? 'en-AU')
const providerCurrency = computed(() => eonx.provider.currency ?? 'AUD')

const gatewayVersion = computed(() => eonx.provider.gateway?.gateway)
const gatewayApiUrl = computed(() => eonx.provider.gateway?.api)
const gatewaySdkUrl = computed(() => eonx.provider.gateway?.sdk)

const loginType = computed(() => eonx.config?.login?.type ?? '')
const isLoginEnabled = computed(
  () => eonx.config?.login?.[loginType.value]?.enabled ?? false
)
const isLogoutEnabled = computed(
  () => eonx.config?.general?.logout?.enabled ?? false
)
const isOauthEnabled = computed(() => {
  const enabled = eonx.config?.login?.oauth?.enabled ?? false

  return loginType.value === 'oauth' && enabled
})
const isRemoteLoginEnabled = computed(() => {
  const enabled = eonx.config?.login?.remote?.enabled ?? false

  return loginType.value === 'remote' && enabled
})
const isRecaptchaEnabled = computed(
  () => eonx.config?.login?.[loginType.value]?.recaptcha ?? false
)
const loginUsernameLabel = computed(
  () => eonx.config?.login?.username?.fieldname ?? 'Username'
)
const isRegistrationFormEnabled = computed(
  () => eonx.config?.registration?.form?.registration_form?.enabled ?? true
)
const registrationForm = computed(() => {
  const { registration_form, ...form } = eonx.config?.registration?.form ?? {}

  return form
})
const registrationHidePassword = computed(() => {
  return registrationForm.value.password?.hidePassword ?? false
})
const isRegistrationAttempts = computed(
  () =>
    eonx.config?.general?.whitelist_v2?.store_registration_attempts?.enabled ??
    false
)

const userMetadata = computed(() => {
  return (eonx.config?.registration?.userMetadata?.metadata ?? []).filter(
    (field) => field.key
  )
})
const userMetadataTypes = computed(() => {
  return Object.fromEntries(
    (eonx.config?.registration?.userMetadataTypes?.userMetadataType ?? [])
      .filter((type) => type.key)
      .map((type) => [type.key, type])
  )
})
const userMetadataSchema = computed(() => {
  return {
    fields: userMetadata.value,
    types: userMetadataTypes.value,
  }
})

const showRebatesCalculation = computed(
  () => eonx.config?.general?.rebates?.showCalculation
)

const paymentConfig = computed(() => eonx.config?.payment ?? {})
const cardProtection = computed(() => paymentConfig.value.card_protection ?? {})
const isCardProtectionEnabled = computed(
  () => cardProtection.value.enabled ?? false
)
const cardProtectionIssuingWhitelist = computed(
  () => cardProtection.value.issuing_networks?.whitelist ?? []
)
const cardProtectionBinsWhitelist = computed(
  () => cardProtection.value.bin?.whitelist ?? []
)
const isQuickBuyEnabled = computed(
  () => paymentConfig.value.checkout?.quickBuy?.enabled ?? true
)
const isAddToCartEnabled = computed(
  () => paymentConfig.value.checkout?.addToCart?.enabled ?? true
)
const isCVVenabled = computed(
  () => paymentConfig.value.cvv_verification?.enabled ?? false
)
const isNABenabled = computed(
  () => paymentConfig.value.card_protection?.['3ds']?.nab?.enabled ?? false
)
const isFZenabled = computed(
  () =>
    paymentConfig.value.card_protection?.['3ds']?.fat_zebra?.enabled ?? false
)

const isStripeEnabled = computed(
  () => paymentConfig.value.card_protection?.['3ds']?.stripe?.enabled ?? false
)
const isSplitEnabled = computed(
  () => paymentConfig.value.splitPayments?.enabled ?? false
)
const isPromotionCodeEnabled = computed(
  () => paymentConfig.value.promotion_code?.enabled ?? false
)
const isMsfFeesPurchaseOrdersProviderFunded = computed(
  () => paymentConfig.value?.msfFees?.order?.providerFunded ?? false
)

const taxationPercentage = computed(
  () => eonx.config?.general?.tax_details?.rate ?? 10
)

const taxationLabel = computed(
  () => eonx.config?.general?.tax_details?.label ?? 'GST'
)

function splitPaymentOrderAllowed(flowType: FlowType) {
  if (!isSplitEnabled.value) {
    return false
  }

  const orderType = getOrderType(flowType)

  return (
    paymentConfig.value.splitPayments?.allowedOrders?.[orderType]?.enabled ??
    false
  )
}

function splitPaymentCombinationsAllowed(
  paymentMethod: PaymentMethodMappedType,
  selectedPaymentMethods: {
    [key in PaymentMethodMappedType]?: boolean
  }
) {
  const allowedCombinations =
    paymentConfig.value.splitPayments?.allowedCombinations ?? []

  // If there are no configurations, then we do not block
  if (allowedCombinations.length === 0) {
    return true
  }

  // 1. We can choose any payment method
  if (Object.keys(selectedPaymentMethods).length === 0) {
    return true
  }

  // 2. Is the method already selected
  if (selectedPaymentMethods[paymentMethod]) {
    return true
  }

  // 3. Checking for the fullness of the combination
  for (const combination of allowedCombinations) {
    if (combination.length === Object.keys(selectedPaymentMethods).length) {
      let count = 0

      for (const item of combination) {
        if (selectedPaymentMethods[item]) {
          count++
        }
      }

      if (count === combination.length) {
        return false // We cannot choose an additional payment method
      }
    }
  }

  // 4. Checking for the possibility of the payment method to be active
  for (const combination of allowedCombinations) {
    let count = 0

    if (combination.length > Object.keys(selectedPaymentMethods).length) {
      for (const item of combination) {
        if (selectedPaymentMethods[item]) {
          count++
        }
      }
      if (
        Object.keys(selectedPaymentMethods).length === count &&
        combination.includes(paymentMethod)
      ) {
        return true
      }
    }
  }

  return false
}

const isPayeesFZenabled = computed(
  () =>
    paymentConfig.value.card_protection?.payees_3ds?.fat_zebra?.enabled ?? false
)
const isPayeesNABenabled = computed(
  () => paymentConfig.value.card_protection?.payees_3ds?.nab?.enabled ?? false
)

const isPayeesStripeEnabled = computed(
  () =>
    paymentConfig.value.card_protection?.payees_3ds?.stripe?.enabled ?? false
)

const isBankAccountsNavEnabled = computed(
  () => paymentConfig.value.nav?.bankAccounts?.enabled ?? true
)
const isCreditCardsNavEnabled = computed(
  () => paymentConfig.value.nav?.creditCards?.enabled ?? true
)

const maxItemsQuantity = computed(
  () => eonx.config?.general.cart.max_item_quantity
)
const isGiftingEnabled = computed(
  () => eonx.config?.general?.gifting?.enabled ?? false
)

const profileConfig = computed(() => eonx.config?.profile ?? {})
const profileConfigForm = computed(() => profileConfig.value?.form ?? {})
const hasEditableProfileFormFields = computed(() =>
  Object.entries(profileConfigForm.value)?.some(
    ([key, value]) =>
      typeof value === 'object' &&
      value.isEditable &&
      value.isVisible &&
      !['password', 'avatar'].includes(key)
  )
)
const isProfileFormEditable = computed(
  () => isProfileEditable.value && hasEditableProfileFormFields.value
)

const isProfileEditable = computed(() => {
  const { user } = useUser()

  if (user.value.isThirdPartyUser) return false

  return profileConfigForm.value?.isEditable ?? false
})

const isPasswordEditable = computed(() => {
  if (!isProfileEditable.value) return false

  return profileConfigForm.value.password?.isEditable ?? false
})

const isAvatarVisible = computed(
  () => profileConfigForm.value.avatar?.isVisible ?? false
)
const isAvatarEditable = computed(() => {
  if (!isProfileEditable.value) return false

  return profileConfigForm.value.avatar?.isEditable ?? false
})

const isDisplayMemberSince = computed(
  () => profileConfig.value.displayMemberSince?.enabled ?? false
)

const userMetadataToDisplay = computed(
  () => profileConfig.value?.userMetadataToDisplay?.metadata ?? []
)
const userMetadataMarketingCommsEmail = computed(() =>
  userMetadataToDisplay.value.find((i) => i.key === 'marketing_comms_email')
)

const zendeskSettings = computed(
  () => eonx.config?.general?.services?.zendesk ?? { enabled: false }
)

const customerServiceNumber = computed(
  () => eonx.config?.general?.general?.customer_service_number ?? ''
)
const generalEmail = computed(() => eonx.config?.general?.general?.email ?? '')
const generalPhone = computed(
  () => eonx.config?.general?.general?.customer_service_number ?? ''
)

const isEstoreDiscountEnabled = computed(
  () => eonx.config?.general?.estore?.discount?.enabled ?? false
)
const estoreSlider = computed(() => paymentConfig.value.points?.estore ?? false)
const estorePointsRatio = computed(() => paymentConfig.value.points?.ratio ?? 0)

const diningSources = computed(
  () => eonx.config?.general?.dining?.sources ?? []
)

const isClaimsEnabled = computed(
  () => eonx.config?.general?.claims?.enabled ?? false
)

const isLoyaltyCardsEnabled = computed(
  () => eonx.config?.general?.loyaltycards?.enabled ?? false
)

const accessRequirements = computed(
  () => eonx.config?.general?.accessRequirements ?? {}
)
const isTermsAcceptanceRequired = computed(
  () => accessRequirements.value.acceptTermsAndConditions === true
)
const isProfileCompletionRequired = computed(
  () => eonx.config?.profile?.forceProfileCompletion?.enabled ?? true
)

const accessRestrictions = computed(
  () => eonx.config?.general?.accessRestrictions ?? {}
)
const isAccessRestrictionEnabled = computed(
  () => accessRestrictions.value.rolesVerification ?? false
)
const accessRestrictionRoles = computed(
  () => accessRestrictions.value.role ?? []
)
const accessRestrictionEnabledRolesList = computed(() =>
  accessRestrictionRoles.value
    .filter((role) => role.enabled)
    .map((role) => role.roleSlug)
)

const useMembershipForBrandContent = computed(
  () => eonx.config?.general?.useMembershipForBrandContent ?? false
)

const brandLogos = computed(() => eonx.metadata?.data?.['brand-logos'])

const isPurchasesFeesEnabled = computed(
  () => !['dentalwallet', 'symbionelite'].includes(providerName.value)
)

const isAustraliaPostProvider = computed(
  () => providerName.value === 'australiapost'
)
const isDentalWalletProvider = computed(
  () => providerName.value === 'dentalwallet'
)
const isGoRewardsProvider = computed(() => providerName.value === 'gorewards')
const isStaffProvider = computed(() => providerName.value === 'staff')
const isSymbioneliteProvider = computed(
  () => providerName.value === 'symbionelite'
)
const isBrandxProvider = computed(() => providerName.value === 'brandx')
const isRacgpplusProvider = computed(() => providerName.value === 'racgpplus')

const isSignatureRewardsProvider = computed(
  () => providerName.value === 'signaturerewards'
)
const isDemoProvider = computed(() => providerName.value === 'demo')
const isCashrewardsProvider = computed(
  () => providerName.value === 'cashrewards'
)

const isSymbioneliteDesignV2 = computed(
  () => isSymbioneliteProvider.value || isDemoProvider.value
)
const isA11yEnabled = computed(() =>
  ['bupa', 'bupasso'].includes(providerName.value?.toLowerCase())
)

const isDefaultTemplate = computed(() => currentTemplate.value === 'default')
const isBillPaymentsTemplate = computed(() =>
  ['bill-payments', 'bill-payments-v1_5'].includes(currentTemplate.value)
)
const isBillPaymentsV15Template = computed(
  () => currentTemplate.value === 'bill-payments-v1_5'
)
const isBupaTemplate = computed(() => currentTemplate.value === 'bupa')
const isAustraliapostTemplate = computed(
  () => currentTemplate.value === 'australiapost'
)

const isCodeWidgetsEnabled = computed(
  () => eonx.config?.theme?.codeWidgets?.enabled ?? false
)

const isAddressFinderEnabled = computed(
  () => eonx.config?.general?.address_validation?.enabled ?? false
)
const addressFinderProvider = computed(
  () => eonx.config?.general?.address_validation?.provider
)
const isGoogleAddressFinderEnabled = computed(
  () => isAddressFinderEnabled.value && addressFinderProvider.value === 'Google'
)

const isUserAddressEnabled = computed(
  () =>
    !(eonx.config?.profile.userAddressManagement?.disableUserAddress ?? false)
)

export const useProvider = () => ({
  providerName,
  providerClientName,
  providerTitle,
  providerLogo,
  providerSquareLogo,
  providerCountry,
  providerLocale,
  providerCurrency,
  isAuProvider,
  isUsProvider,
  isGbProvider,
  isCaProvider,
  gatewayVersion,
  gatewayApiUrl,
  gatewaySdkUrl,
  paymentConfig,
  isCardProtectionEnabled,
  cardProtectionIssuingWhitelist,
  cardProtectionBinsWhitelist,
  isSplitEnabled,
  splitPaymentOrderAllowed,
  splitPaymentCombinationsAllowed,
  isFZenabled,
  isCVVenabled,
  isNABenabled,
  isStripeEnabled,
  isRegistrationAttempts,
  userMetadata,
  userMetadataTypes,
  userMetadataSchema,
  showRebatesCalculation,
  customerServiceNumber,
  generalEmail,
  generalPhone,
  isBankAccountsNavEnabled,
  isCreditCardsNavEnabled,
  profileConfig,
  profileConfigForm,
  maxItemsQuantity,
  isPayeesFZenabled,
  isPayeesNABenabled,
  isPayeesStripeEnabled,
  isGiftingEnabled,
  isClaimsEnabled,
  isLogoutEnabled,
  isEstoreDiscountEnabled,
  estoreSlider,
  estorePointsRatio,
  diningSources,
  isQuickBuyEnabled,
  isAddToCartEnabled,
  isRegistrationFormEnabled,
  registrationForm,
  registrationHidePassword,
  loginType,
  isRecaptchaEnabled,
  loginUsernameLabel,
  isLoginEnabled,
  isOauthEnabled,
  isRemoteLoginEnabled,
  zendeskSettings,
  isPurchasesFeesEnabled,
  useMembershipForBrandContent,
  brandLogos,
  isTermsAcceptanceRequired,
  isProfileCompletionRequired,
  isLoyaltyCardsEnabled,
  isAustraliaPostProvider,
  isDentalWalletProvider,
  isGoRewardsProvider,
  isStaffProvider,
  isSymbioneliteProvider,
  isBrandxProvider,
  isRacgpplusProvider,
  currentTemplate,
  currentTemplateConfig,
  isDefaultTemplate,
  isBillPaymentsTemplate,
  isBillPaymentsV15Template,
  isBupaTemplate,
  isAustraliapostTemplate,
  isSignatureRewardsProvider,
  isSymbioneliteDesignV2,
  isA11yEnabled,
  isProfileEditable,
  isPasswordEditable,
  isAvatarEditable,
  isAvatarVisible,
  isDisplayMemberSince,
  isCodeWidgetsEnabled,
  userMetadataMarketingCommsEmail,
  accessRestrictionRoles,
  accessRestrictionEnabledRolesList,
  isAccessRestrictionEnabled,
  isCashrewardsProvider,
  taxationPercentage,
  taxationLabel,
  isPromotionCodeEnabled,
  isGoogleAddressFinderEnabled,
  isMsfFeesPurchaseOrdersProviderFunded,
  isUserAddressEnabled,
  isProfileFormEditable,
})
